import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Confirmation } from "page_components/contact"

const NotFoundPage = () => {
  const title = "Podziękowanie"

  return (
    <Layout seo={{ title: title }}>
      <Breadcrumbs title={title} />
      <PageHeader title="Twoja wiadomość została wysłana" />
      <Confirmation />
    </Layout>
  )
}

export default NotFoundPage
